import React from "react";

const East = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <p>From The East</p>
          <i>“The Pillars of Tomorrow”</i>
        </div>
        <div className="card-body">
          Greetings Brethren,
          <p>
            As we begin a new month, let us turn our attention to one of the
            most cherished principles of Freemasonry: Brotherly Love. This
            virtue is not merely a sentiment but a guiding force that shapes who
            we are as Masons. It is the glue that binds us together, allowing us
            to labor in harmony and build a stronger lodge, family, and
            community.
          </p>
          <p>Brotherly Love is the Main Pillar of Freemasonry</p>
          <p>
            Brotherly Love stands at the heart of our teachings, symbolizing the
            deep bond we share as Masons. Without it, the very foundation of our
            Craft would falter. Just as a well-constructed edifice relies on the
            strength of its pillars, our station depends on love for our
            brethren, families, and the community we serve.
          </p>
          <p>
            This love is not passive; it is active and intentional. It calls us
            to extend a helping hand, to forgive, and to spread joy wherever we
            go. As the Worshipful Master of our lodge, I encourage you to
            reflect on how Brotherly Love sustains our work and drives our
            mission.
          </p>
          <p>February Challenge:</p>
          <p>Acts of Charity and Forgiveness</p>
          <p>
            This month, I challenge each of you to put Brotherly Love into
            action:
          </p>
          <p>1. Perform Random Acts of Charity:</p>
          <p>
            Whether it's helping a neighbor, volunteering your time, or donating
            to a worthy cause, let us make kindness a daily habit. Simple
            gestures can have profound effects.
          </p>
          <p>2. Forgive and Heal:</p>
          <p>
            If there is someone who has wronged you, now is the time to extend
            the hand of forgiveness. Harboring resentment only weakens our
            spirit. As Masons, we are called to reconcile and mend the bonds of
            fellowship.
          </p>
          <p>3. Spread Joy and Love:</p>
          <p>
            Take time to uplift others with kind words, a smile, or an act of
            generosity. Our small actions can create ripples that transform
            lives.
          </p>
          <p>
            Brethren, as we work together this month, may our actions inspire
            others to see the light of Freemasonry. Let us strengthen our bonds
            with one another, support our families, and enrich our community.
          </p>
          <p>
            Let us come together, not only as Masons but as brothers united by
            love and purpose. I look forward to seeing each of you soon and
            witnessing the light of Brotherly Love shine brightly in all that we
            do.
          </p>
        </div>
        <div className="card-footer">
          <p>Donald H. Rackley Jr.</p>
          <p>Worshipful Master, PG Lodge #115</p>
        </div>
      </div>
    </>
  );
};

export default East;
